<template>
  <div>
    <q-btn
      v-if="props.row.editFlag !== 'C'"
      flat
      size="12px"
      color="orange"
      icon="chevron_right"
      @click="rowClickDetail(props.row, props.pageIndex)" 
    />
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="dialogDetail" position="bottom">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="requestContents" />
          <slot name="customArea" v-bind:props="props" v-bind:col="col"></slot>
          <div class="col-6">
            <div class="mini-dailog-title">
              <i class="pe-7s-angle-right-circle"></i>
              {{$language('개선진행')}}
            </div>
          </div>
          <div class="col-6 mini-dailog-btn-impr">
            <div v-if="inputEditable">
              <q-btn-group outline>
                <c-btn
                  v-if="inputEditable"
                  :showLoading="false"
                  label="개선"
                  color="red-6"
                  text-color="white"
                  @btnClicked="innerBtnClicked(props, '1')"
                />
                <c-btn
                  v-if="inputEditable"
                  :showLoading="false"
                  label="즉시"
                  color="light-blue"
                  text-color="white"
                  @btnClicked="innerBtnClicked(props, '2')"
                />
              </q-btn-group>
            </div>
          </div>
          <div class="col-12 border-impr-dialog">
            <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
              <q-chip outline square
                v-for="(item, index) in $comm.inspectIbmTagItems(props.row, tableKeys)"
                :key="index"
                :color="item.color"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click="openImprPop(item, props.row)">
                {{item.title}}
              </q-chip>
            </template>
            <template v-else>
              {{$language('등록된 개선요청 및 즉시조치가 없습니다.')}}
            </template>
          </div>
          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose"
              />
              <c-btn
                v-if="inputEditable&&acceptBtnShow"
                :showLoading="false"
                label="적용"
                icon="check"
                color="amber-7"
                @btnClicked="dialogSubmit"
              />
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
export default {
  name: 'mobileTableImpr',
  props: {
    col: {
      type: Object,
      default: function() {
        return {
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    props: {
      type: Object,
      default: function() {
        return { }
      }
    },
    isImmShow: {
      type: Boolean,
      default: false
    },
    inputEditable: {
      type: Boolean,
      default: false
    },
    rowContentsCol: {
      type: String,
      default: ''
    },
    requestContentsCols: {
      type: Array,
      default: function() {
        return []
      }
    },
    tableKeys: {
      type: Array,
      default: function() {
        return []
      }
    },
    tableKey: {
      type: String,
      default: ''
    },
    ibmTaskTypeCd: {
      type: String,
      default: ''
    },
    ibmTaskUnderTypeCd: {
      type: String,
      default: ''
    },
    acceptBtnShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dialogDetail: false,
      requestImprRow: null,
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    requestContents() {
      let contents = '개선';
      if (this.requestContentsCols && this.requestContentsCols.length > 0) {
        contents = this.requestContentsCols.map(col => {
          return this.props.row[col]
        }).join(' / ')
      }
      return contents;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    rowClickDetail(_row, _idx) {
      this.dialogDetail = true;
    },
    dialogSubmit() {
      this.$set(this.props.row, 'editFlag', 'U');
      this.$set(this.props.row, 'chgUserId', this.$store.getters.user.userId);
      this.dialogDetail = false;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    openImprPop(data, row) {
      this.requestImprRow = row
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
          }
        }
        this.$emit('imprChange')
      }
    },
    innerBtnClicked(props, btn) {
      this.requestImprRow = props.row
      let _tablekey = '';
      if (this.tableKeys && this.tableKeys.length > 0) {
        _tablekey = this.tableKeys.map(col => {
          return props.row[col]
        }).join('/')
      }
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: this.requestContents,
          relationTableKey: this.tableKeys.length > 0 ? _tablekey : props.row[this.tableKey],
          ibmTaskTypeCd: this.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: this.ibmTaskUnderTypeCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: this.requestContents,
          relationTableKey: this.tableKeys.length > 0 ? _tablekey : props.row[this.tableKey],
          ibmTaskTypeCd: this.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: this.ibmTaskUnderTypeCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.col1) {
        let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
        if (sData.indexOf(data.col1.sopImprovementId) === -1) {
          this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
            this.requestImprRow.sopImprovementIds + ',' + data.col1.sopImprovementId : data.col1.sopImprovementId
          this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
            this.requestImprRow.ibmStepNames + ',' + data.col1.ibmStepName : data.col1.ibmStepName
          this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
            this.requestImprRow.ibmClassCds + ',' + data.col1.ibmClassCd : data.col1.ibmClassCd
          this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
            this.requestImprRow.ibmTooltip + '│' + data.col1.ibmTooltip : data.col1.ibmTooltip
        }
        this.$emit('imprChange')
      }
    },
  }
};
</script>